import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import MainSection from "../components/MainSection";
import Notificationpage from "./Notificationpage";
import "../assets/css/style.css";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
const Loginpage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
     
      navigate('/profile');
    } 
  }, [navigate]);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
 const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleemailOrPhoneChange = (e) => {
      setEmailOrPhone(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apidata= {
      "status": "success",
      // "data": { user data or token rerurn from api},
      "message": "User details retrieved successfully"
    }
    
    if (apidata.status === 'success' ) {
     
      const user = {
        name: 'Test acccount',
        email: emailOrPhone,
        password: password,
        number: '1234567890' 
      };
      localStorage.setItem('user', JSON.stringify(user));
      console.log('Form submitted:', user);
      handleClose();
      setShow(false);
      navigate('/home');
    } else {
      handleShow();

    }
  };
  const handlebuttonClick=()=>{
    navigate('/signup');
  }
  const [showPasswordModal, setShowPassModal] = useState(false);
  const handlePassClose = () => setShowPassModal(false);
  const handlePassShow = () => setShowPassModal(true);
  const handlePasswordClick =()=>{
    handlePassShow();
  }
const handleForgotPassword=(e)=>{
  e.preventDefault();
  handlePassClose();
  console.log("password reset email should be send here");
}
  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
         
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box">
                    <div className="fieldset">
                      <div className="title mb-5">
                        <h2>Login to Betpro wallet</h2>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="inputs field-container">  
                          <form method="post" onSubmit={handleSubmit}>
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Email or phone number"
                              value={emailOrPhone}
                              onChange={handleemailOrPhoneChange}
                            />
                            <input
                              type="password"
                              className="mb-4"
                              placeholder="Enter password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <div className="forgot-password">
                              <a onClick={handlePasswordClick}>Forgot password?</a>
                            </div>
                            <button type="submit" className="btn-signin mb-3">
                              Sign in
                            </button>
                            </form>
                            <a href="/signup" className="signup mb-3">
                              Don't have an account?
                            </a>
                            <button className="btn-create-account mb-3" onClick={handlebuttonClick}>
                              {" "}
                              Create account
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
       
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header >
          <Modal.Title>Sign in Failed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your username or password is incorrect.</p>
          <p> Please try again.</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>
        
      </Modal>
      <Modal show={showPasswordModal} onHide={handlePassClose}  centered>
        <Modal.Header >
          <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You will receive instructions for resetting your password.</p>
          <form method="post"  onSubmit={handleForgotPassword}>
          <div className='forgotinputs'><input type="text"  placeholder="Email or phone number" />
          <button type="submit" className="btn-signin">Send my Password</button> 
            </div>                
          </form>
         
                                          
        </Modal.Body>
        
      </Modal>
      <MainSection />
      <Footer />
    </div>
  );
};

export default Loginpage;
