import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Nav from '../components/Navigation';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
const ProfilePage = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);
  useEffect(() => {
    if (!user) {
      console.log("inside ifuser",user);
      navigate('/');
    } 
  }, );

  const handleGoToWebsite= ()=>
  {
    console.log("handle Go To Bet Pro website Button");
  }
  const handleLogout= ()=>
  {
    console.log("logout");
    localStorage.removeItem('user');
    navigate('/');
  }
  return (
    <div>
       <Header />

       <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
           
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset">
                      <div className="title mb-5">
                        <h2 className="mt-5">Active Betpro account</h2>
                        <div className="account mt-5">
                        <div className="whatappp mt-5 ">
                        <div className="btn-active-account">
                          <h5>Username: <span style={{marginLeft:'4px'}}> {parsedUser.name}</span>
                        </h5></div>
                        </div>
                        <div className="whatappp mb-5 ">
                        <div  className="btn-active-account">
                          <h5>Password:   <span style={{marginLeft:'4px'}}>{parsedUser.password}</span></h5></div>
                        </div>
                        <div className="whatappp mb-5 ">
                        <div  className="btn-active-website">
                          <h5 onClick={handleGoToWebsite}>Go to BetPro website</h5></div>
                        </div>
                        </div>
                        <div className="withdraw mt-5 mb-5">
                        <div className="cus-btn1 btn btn-default " >
                          <h5>
                            <span className=""></span>+ Deposit
                          </h5>
                        </div>
                        <div className="cus-btn1 btn btn-default" >
                          <h5>
                            <span className=""></span>Withdraw
                          </h5>
                        </div>
                        </div>

                        <div className="whatappp mt-5 mb-5 text-center">
                        <div  className="btn-whatappp"><h5><FaWhatsapp style={{ color: 'green', fontSize: '1.5em' }} />Contact on Whatapp</h5></div>
                        </div>
                
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
         
          </div>
        </div>
      </div>

      <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
            <form method="post" onSubmit={handleLogout}>
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset">
                      <div className="title mb-5">
                        
                      <div className="account mt-5">
                     <h3>Personal profile</h3>
                     <div className="info mt-5 mb-5">
                      <p>
                     <strong className="mb-3 ">Name:</strong>
                     <span className="mb-3 right">{parsedUser.name}</span>
                     </p>
                     <p>
                     <strong className="mb-3 ">Email:</strong>
                     <span className="mb-3 right">{parsedUser.email}</span>
                     </p>
                     <p>
                     <strong className="mb-3 ">Whatappp Number</strong>
                     <span className="mb-3 right">{parsedUser.number}</span>
                     </p>
                     
                     </div>
                     

                        <div className="whatappp mb-5 ">
                        <button type="submit" className="btn-active-website"><h5>Logout</h5></button>
                        </div>
                        </div>
                      </div>
                    </div>


                    
                  </div>
                </div>
              </div>
           </form>
          </div>
        </div>
      </div>
       <Nav/>
      
    </div>
  )
}

export default ProfilePage;
