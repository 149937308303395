import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import download from '../assets/images/download.png';
import cricket from '../assets/images/cricket.png';
import { FaWhatsapp } from 'react-icons/fa';
import Nav from '../components/Navigation';
import { useNavigate } from "react-router-dom";
import MainSection from "../components/MainSection";
const Homepage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/');
    } else {     
    }
  }, [navigate]);
  const handledepositclick=()=>{
    navigate('/deposit')
  }
  const handlewithdrawclick=()=>{
    navigate('/withdraw')
  }
  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
       

              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset">
                      <div className="title mb-5">
                        <h2 className="mt-5">Active Betpro account</h2>
                        <div className="account mt-5">
                          <h3 className="text-center  mb-3"> ضروری ہدایات</h3>

                          <ul>
                            <li className="mb-3">ڈپازٹ کرنے کہ بعد آپکا Betpro اکائونٹ ایکٹو ہو جاۓ گا</li>
                            <li className="mb-3">کا username اور password یہیاں پر ظاہر ہوگا Betpro</li>
                            <li className="mb-3">ڈپازٹ کی گئی رقم آپکے Betpro اکائونٹ میں شامل ہوگی</li>

                          </ul>
                        </div>
                        <div className="withdraw mt-5 mb-5">
                        <button className="cus-btn1 btn btn-default "  onClick={handledepositclick}>
                          <h5>
                            <span className=""></span>+Deposit
                          </h5>
                        </button>
                        <button  className="cus-btn1 btn btn-default" onClick={handlewithdrawclick}>
                          <h5>
                            <span className=""></span>Withdraw
                          </h5>
                        </button>
                        </div>

                        <div className="whatappp mt-5 mb-5 text-center">
                        <button className="btn-whatappp"><h5><FaWhatsapp style={{ color: 'green', fontSize: '1.5em',marginRight:'3px' }} />Contact on Whatapp</h5></button>
                        </div>
                
                      </div>
                   

                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>


      <MainSection />
  
        <Nav/>
    </div>
  )
}

export default Homepage;
