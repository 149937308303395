import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import download from "../assets/images/download.png";
import cricket from "../assets/images/cricket.png";
import MainSection from "../components/MainSection";
import { useNavigate } from "react-router-dom";
const Signuppage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/profile");
    }
  }, [navigate]);
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [password, setPassword] = useState("");
  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleWhatsappNumberChange = (event) => {
    setWhatsappNumber(event.target.value); 
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      const user = {
        name: fullName,
        email: emailAddress,
        password: password,
        number: whatsappNumber 
      };
      localStorage.setItem('user', JSON.stringify(user));
      console.log('Form submitted:', user);
      navigate('/profile');
    
  };
  const handlebuttonClick=()=>{
    navigate('/');
  }
  return (
    <div>
      <Header />
      <div className="register-wrapper">
        <div className="main-register">
          <div className="container">
          
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-register-box">
                    <div className="fieldset">
                      <div className="title mb-5">
                        <h2>Register your Betpro account</h2>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="inputs field-container">
                          <form method="post" onSubmit={handleSubmit}>
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Full Name"
                              name="fullName" 
                              value={fullName}
                              onChange={handleFullNameChange}
                            />
                            <input
                              type="text"
                              className="mb-4"
                              placeholder="Email Address"
                              name="emailAddress"
                              value={emailAddress}
                              onChange={handleEmailAddressChange}
                            />
                            <input
                              type="number"
                              className="mb-4"
                              placeholder="Whatsapp Number"
                              name="whatsappNumber"
                              value={whatsappNumber}
                              onChange={handleWhatsappNumberChange}
                            />
                            <input
                              type="password"
                              className="mb-4"
                              placeholder="Enter password"
                              name="password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <button type="submit" className="btn-signin mb-3">
                              Sign up
                            </button>
                            </form>
                            <a href="/" className="signup mb-3">
                              Already have an account?
                            </a>
                            <button className="btn-create-account mb-3" onClick={handlebuttonClick}>
                              {" "}
                             Sign in
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
          </div>
        </div>
      </div>
      <MainSection />
      <Footer />
    </div>
  );
};

export default Signuppage;
