import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Nav from '../components/Navigation';
import easypaisa from '../assets/images/deposit/easypaisa.png';
import jazzcash from '../assets/images/deposit/jazzcash.png';
import bank from '../assets/images/deposit/bank.png';
import upaisa from '../assets/images/deposit/upaisa.png';
import nayapay from '../assets/images/deposit/nayapay.png';
import { GrCopy } from "react-icons/gr";
import { FaFileUpload } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from 'react-bootstrap/Modal';
const Depositpage = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);
  useEffect(() => {

    if (!user) {
      navigate('/');
    } else {     
    }
  }, [navigate]);

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  
  };
  const options = [
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
    { value: '5000', label: '5000' },
    { value: '10000', label: '10000' },
  ];
  const [amount, setAmount] = useState(0);
  const handleChange = (event) => {
    setAmount(event.target.value);
  };
  const [selectedPrice, setSelectedPrice] = useState('');

  const handleClick = (price) => {
    setSelectedPrice(price);
    setAmount(price);
  };
  useEffect(() => {
  }, [selectedPrice]);

  const paymentOptions = [
    { id: 1, image: easypaisa, alt: 'Easypaisa', text: 'Easypaisa' },
    { id: 2, image: jazzcash, alt: 'JazzCash', text: 'Jazz Cash' },
    { id: 3, image: bank, alt: 'Bank Transfer', text: 'Bank Transfer' },
    { id: 4, image: easypaisa, alt: 'Easypaisa Fast', text: 'Easypaisa Fast' },
    { id: 5, image: upaisa, alt: 'Upaisa', text: 'Upaisa' },
    { id: 6, image: nayapay, alt: 'Naya Pay', text: 'Naya Pay' },
  ];
  const [selectedPayment, setSelectedPayment] = useState(null); 

  const handleMethodChange = (optionId) => {
    setSelectedPayment(optionId);
  };
  useEffect(() => {
  }, [selectedPayment]);

  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!selectedPayment){
      setMsg('Please select a Payment Option to proceed')
      handleShow();
    }
    const selectedPaymentOption = paymentOptions.find(
      (option) => option.id === selectedPayment
    ).text;
    const formData = {
      amount,
      selectedPrice,
      selectedPaymentOption,
      selectedFile,
    };
    console.log("Form Data:", formData);
    if(!selectedFile){
      setMsg('Please upload image to proceed')
      handleShow();
    }else if(!(amount >500 && amount < 50000)){
      setMsg('Please write a correct amount to proceed')
      handleShow();
    }else{
    setMsg('Deposit request created successfully')
    handleShow();
    }
  };
  return (
    <div>
      <Header />

      <div className="main-deposit-wraper">
        <div className="container">
          <div className="middle-depsoit">
            <div className="fieldset-deposit">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-3 mb-5 text-center">Deposit funds</h2>
                  <h3 className="hh3 mt-3">Payment Method</h3>
                  <hr />
                  <div className="row mt-3">
                    {paymentOptions.map((option) => (
                      <div key={option.id} className="col-4 mt-5">
                        <div className="deposit-easypaisa">
                          <label htmlFor={option.id}>
                            <input
                              type="radio"
                              id={option.id}
                              name="payment"
                              value={option.id}
                              checked={selectedPayment === option.id}
                              onChange={() => handleMethodChange(option.id)}
                            />
                            <img src={option.image} alt={option.alt} />
                          </label>
                        </div>
                        <div
                          className={`deposit-text ${
                            selectedPayment === option.id ? "method-active" : ""
                          }`}
                        >
                          <h5>{option.text}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <h3>Amount</h3>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <h3>Rs 500 - Rs 50,000</h3>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <p className="wallet-number">Easypaisa Wallet number: </p>
                    <p className="wallet-number">Easypaisa Wallet name: </p>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <p className="wallet-number">
                      {parsedUser.number}
                      <CopyToClipboard text={parsedUser.number}>
                        <span className="ic-copy">
                          <GrCopy className="icon" />
                        </span>
                      </CopyToClipboard>{" "}
                    </p>
                    <p className="wallet-number">
                      {parsedUser.name}
                      <CopyToClipboard text={parsedUser.name}>
                        <span className="ic-copy">
                          <GrCopy className="icon" />
                        </span>
                      </CopyToClipboard>
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3">
                <div className="col-6">
                  <div className="amount-detail mt-3">
                    <p className="wallet-number">
                      Amount (Min 500 PKR / Max 50,000 PKR)
                    </p>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <div className="input-wrapper">
                      <span className="currency-symbol">Rs</span>
                      <input
                        type="number"
                        id="amount"
                        className="cus-btn1 btn btn-default account-btn"
                        placeholder="0.00"
                        onChange={handleChange}
                        value={amount}
                        min="500"
                        max="50000"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                {options.map((option) => (
                  <div key={option.value} className="col-3">
                    <button
                      key={option.value} 
                      className={`price-btn ${
                        selectedPrice === option.value ? "p-active" : ""
                      }`}
                      onClick={() => handleClick(option.value)}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
              <hr />

              <div className="row mt-3 mb-3">
                <div className="col-6">
                  <div className="amount-detail mt-4">
                    <h3 className="wallet-number">Uplaod receipt:</h3>
                
              
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="amount-detail-number mt-3">
                    <div className="cus-btn1 btn btn-default mb-view">
                      <label htmlFor="fileId">
                        <span className="copy-txt">
                      
                        {selectedFile ? 'Change Files' : 'Choose Files'}
                          <LuImagePlus size={25} className="copy-icon" />
                        </span>
                      </label>
                      <input type="file" id="fileId" onChange={handleFileChange} />
                    </div>
                  </div>
                </div>
                <button type="submit" className="confirm-btn  mt-5 mb-3"  onClick={handleSubmit}>
                  {" "}
                  Confirm
                </button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <Nav />
      <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header >
          <Modal.Title>Deposit Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>
        
      </Modal>
    </div>

  );
}

export default Depositpage
