import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Nav from '../components/Navigation';
import { useNavigate } from "react-router-dom";
const Transactionspage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/');
    } 
  }, [navigate]);

  const transactions = [
    {
      date_time: "10-10-2023 02:23 AM",
      amount: 1000.00,
      payment_method: "easypaisa",
      status: "Accepted",
    },
    {
      date_time: "2023-11-15 11:59 PM",
      amount: 500.50,
      payment_method: "credit card",
      status: "Pending",
    },
    {
      date_time: "2023-12-01 08:00 AM",
      amount: 250.00,
      payment_method: "debit card",
      status: "Completed",
    },
    {
      date_time: "2024-01-10 03:15 PM",
      amount: 78.25,
      payment_method: "cash",
      status: "Processed",
    },
    {
      date_time: "2024-02-14 07:42 PM",
      amount: 1500.75,
      payment_method: "bank transfer",
      status: "Successful",
    },
  ];
  
  return (
    <div>
          <Header />
          <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
            <form method="post" action=" " >
              <div className="row align-items-top">
                <div className="col-md-12">
                  <div className="left-login-box1">
                    <div className="fieldset">
                      <div className="title mb-5">
                        <h2 className="mt-5">Transactions</h2>
                        
                      {transactions.map((transaction , index)=>(
                        <div key={index} className="account mt-3">
                        <data style={{fontSize:'12px'}}>{transaction.date_time.split(' ')[0]} | {transaction.date_time.split(' ')[1]}</data>
                        <p style={{margin:'0'}} className="mt-3">
                          Deposit accepted in the amount of {transaction.amount.toFixed(2)} PKR 
                          </p>
                          <p>via {transaction.payment_method} ({transaction.status})</p>
                          </div>
                      ))}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      <Nav/>
    </div>
  )
}

export default Transactionspage
